import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import loginCard from "../../assets/img/login_card_logo.png"
import Card from 'react-bootstrap/Card'
import { Form, Button, } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import EmptySpaceFieldValid from "../Validation/EmptySpaceValid";
import axios from 'axios';
import { toast } from "react-toastify";
import CheckValid from "../Validation/ErrorValidation";

const ResetPasswordDetail = () => {

    const [loginState, setLoginState] = useState({
        newPassword: "",
        confirmPassword: ""
    });

    let { id } = useParams();
    const code = id.split('-')[1];
    const navigate = useNavigate() 
    const [newpassworderror, setNewPasswordError] = useState("")
    const [newPasswordShow, setNewPasswordShow] = useState(false)
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)
    const [confirmPassworderror, setConfirmPasswordError] = useState("")

    function userLogin(e) {
        e.preventDefault();
        if (loginState?.newPassword !== "" && loginState?.confirmPassword !== "") {
            if (loginState?.newPassword === loginState?.confirmPassword) {
                axios.post(`${process.env.REACT_APP_BASE_URL}api/reset-password`, {
                    code: code,
                    password: loginState?.confirmPassword
                }).then(response => {
                    navigate("/login")
                    toast.success(response.data.message);
                }).catch(error => {
                    console.error("Error resetting password:", error);
                    toast.error("Failed to reset password. Please try again later.");
                });
            } else {
                setConfirmPasswordError("Confirm password does not match");
                setNewPasswordError("New password does not match");
            }
        } else {
            CheckValid(e.target.value, { type: 'confirmpassword', error: setConfirmPasswordError })
            CheckValid(e.target.value, { type: 'newpassword', error: setNewPasswordError })
        }
    }

    return (<section className="admin-login" style={{ background: "#000000" }}>
        <Container >
            {/* <Image src={gunsot1} alt="Gunshot" /> */}
            <Row>
                <Col md={12}>
                    <Col md={4} >
                        <Card style={{ width: '22rem', backgroundColor: "white", color: "black" }} className="p-4">

                            {/* <div className="login-form"> */}
                            <div className="mt-1 text-center">
                                <Image src={loginCard} height={70} width={70} onKeyDown={EmptySpaceFieldValid} />
                            </div>
                            <span className="login-logo-under-text text-center">Enter a new password below to
                                change your password </span>
                            <div className="login-form">

                                <Form className="mt-2" >
                                    <Form.Group className="mb-2" controlId="formBasicEmail">
                                        <Form.Label>New Password</Form.Label>
                                        <span style={{ position: "relative" }}>
                                            <Form.Control
                                                type={newPasswordShow ? "text" : "password"}
                                                placeholder="New Password"
                                                onChange={(e) => setLoginState({ ...loginState, newPassword: e.target.value })}
                                                value={loginState.newPassword}
                                                onKeyDown={EmptySpaceFieldValid}
                                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'newpassword', error: setNewPasswordError })}

                                            />
                                            <span className="showpassbtn-reset" onClick={() => setNewPasswordShow(!newPasswordShow)}>
                                                {newPasswordShow ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>) :
                                                    (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="30" height="30" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>)}
                                            </span>
                                        </span>
                                        {newpassworderror && <span className="text-danger">{newpassworderror}</span>}
                                    </Form.Group>

                                    <Form.Group className="mb-4" controlId="formBasicPassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <span style={{ position: "relative" }}>
                                            <Form.Control
                                                type={confirmPasswordShow ? "text" : "password"}
                                                placeholder="Confirm New Password"
                                                onChange={(e) => setLoginState({ ...loginState, confirmPassword: e.target.value })}
                                                value={loginState.confirmPassword}
                                                onKeyDown={EmptySpaceFieldValid}
                                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'confirmpassword', error: setConfirmPasswordError })}

                                            />
                                            <span className="showpassbtn-reset mb-1" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}>
                                                {confirmPasswordShow ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>) :
                                                    (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>)}
                                            </span>
                                            {confirmPassworderror && <span className="text-danger">{confirmPassworderror}</span>}
                                        </span>
                                    </Form.Group>
                                    <div>
                                        <Button style={{ width: "100%", border: "none" }} type="submit" onClick={userLogin}>
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                            {/* </div> */}
                        </Card>

                    </Col>
                </Col>
            </Row>
        </Container>
    </section>)
}

export default ResetPasswordDetail;