import axios from 'axios';

const UploadFileAPI = async (formData) => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/uploads`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
    return response;
};
  
export default UploadFileAPI;