import React, { useState } from "react";
import loginCard from "../../assets/img/login_card_logo.png"
import { Image } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import CommonewModel from "../CommonModel/commonModel";
import { useSelector } from "react-redux";
const Sidebar = () => {
    // const LocationURL = useLocation().pathname;
    const { loginUserDetail } = useSelector(state => state.adminReducers);
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const LocationURL = useLocation().pathname;

    const openModal = () => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Create New Box",
            subtitle: "Create New Box",
            modalType: "log-out",
        })
    };

    const getFirstName=()=>{
        const fullName = loginUserDetail?.list?.full_name;
        let initials = "";
        let name =""
        if (fullName) {
            const words = fullName.split(" ");
            initials = words.map(word => word.charAt(0).toUpperCase()).join("");
            name = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");

        }
        return {
            initials:initials.toUpperCase(),
            name:name
        }
    }
    return (<section className="sidebar">
        <div className={"panelwrapper active"}>
            <div style={{marginLeft:"7px"}}>
                <Image src={loginCard} style={{height:"70px",width:"70px",borderRadius:"50px"}}></Image>
                <span className="logo-text ms-2">210 ARMORY</span>
            </div>

            {/* <span className="username-circle">KG</span> */}
            <div className="mt-4 mx-3">
                <span className="username-circle">{getFirstName()?.initials}</span>
                <span className="ms-2" style={{ fontSize: "18px", fontFamily: "M PLUS 1", color: "#313D15",fontWeight:"600" }}>{getFirstName()?.name}</span>
                {/* <span style={{ fontSize: "14px",}}>admin</span> */}
            </div>
            <div className="mt-4">
                <div id="menu2">
                    <ul>
                        {/* <li className="my-4 ms-1"> */}
                        <li className={LocationURL ==="/user-management"?"active ":""}>

                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5596 9.2841L17.2525 3.28409C16.645 2.18202 15.5023 1.5 14.2633 1.5H7.73671C6.49771 1.5 5.35501 2.18202 4.74751 3.28409L1.44041 9.2841C0.853212 10.3495 0.853212 11.6505 1.44041 12.7159L4.74751 18.7159C5.35501 19.818 6.49771 20.5 7.73671 20.5H14.2633C15.5023 20.5 16.645 19.818 17.2525 18.7159L20.5596 12.7159C21.1468 11.6505 21.1468 10.3495 20.5596 9.2841ZM19.2785 10.0195L15.9714 4.01948C15.6243 3.38972 14.9713 3 14.2633 3H7.73671C7.02871 3 6.37571 3.38972 6.02861 4.01948L2.72151 10.0195C2.38591 10.6283 2.38591 11.3717 2.72151 11.9805L6.02861 17.9805C6.37571 18.6103 7.02871 19 7.73671 19H14.2633C14.9713 19 15.6243 18.6103 15.9714 17.9805L19.2785 11.9805C19.6141 11.3717 19.6141 10.6283 19.2785 10.0195Z" fill="#313D15" stroke="#313D15" stroke-width="0.5" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 8.75C12.2176 8.75 13.2047 9.7574 13.2047 11C13.2047 12.2426 12.2176 13.25 11 13.25C9.7824 13.25 8.7953 12.2426 8.7953 11C8.7953 9.7574 9.7824 8.75 11 8.75ZM14.6746 11C14.6746 8.92893 13.0294 7.25 11 7.25C8.9706 7.25 7.3254 8.92893 7.3254 11C7.3254 13.0711 8.9706 14.75 11 14.75C13.0294 14.75 14.6746 13.0711 14.6746 11Z" fill="#313D15" stroke="#313D15" stroke-width="0.5" />
                            </svg>
                            <Link to={"/user-management"} className="ms-2">User Management</Link>
                        </li>
                        {/* <li className="ms-1 my-4"> */}
                            <li className={LocationURL ==="/webinar-management"?"active my-4":"my-4"}>
                            <svg width="22" height="22" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.26179 15.7915H17.7382C19.2919 15.7915 20.5562 14.5272 20.5562 12.9727V3.63442C20.5562 2.07999 19.2919 0.815674 17.7382 0.815674H4.26179C2.70804 0.815674 1.44373 2.07999 1.44373 3.63442V12.9727C1.44373 14.5272 2.70804 15.7915 4.26179 15.7915ZM2.68123 3.63442C2.68123 2.76267 3.39073 2.05386 4.26179 2.05386H17.7382C18.6099 2.05386 19.3187 2.76267 19.3187 3.63442V12.9727C19.3187 13.8445 18.6092 14.5533 17.7382 14.5533H4.26179C3.39004 14.5533 2.68123 13.8445 2.68123 12.9727V3.63442Z" fill="#313D15" stroke="#313D15" stroke-width="0.6" />
                                <path d="M9.18974 11.7648C9.47437 11.9119 9.78237 11.9848 10.0897 11.9848C10.4857 11.9848 10.8789 11.8638 11.2165 11.6259L13.6592 9.89892C14.1769 9.53317 14.4856 8.93642 14.4856 8.30254C14.4856 7.66936 14.1762 7.07261 13.6592 6.70754L11.2158 4.98123C10.6163 4.55773 9.84012 4.50411 9.18906 4.84236C8.53799 5.17923 8.13306 5.84473 8.13306 6.57761V10.0295C8.13374 10.7624 8.53799 11.4279 9.18974 11.7648ZM9.37124 6.57761C9.37124 6.30467 9.51562 6.06679 9.75831 5.94098C9.86418 5.88598 9.97762 5.85848 10.0897 5.85848C10.2334 5.85848 10.3764 5.90317 10.5015 5.99186L12.9442 7.71817C13.1367 7.85361 13.2467 8.06742 13.2467 8.30323C13.2467 8.53904 13.136 8.75217 12.9435 8.88898L10.5008 10.6153C10.2781 10.7727 10.001 10.792 9.75762 10.6662C9.51493 10.5404 9.37056 10.3032 9.37056 10.0295L9.37124 6.57761Z" fill="#313D15" stroke="#313D15" stroke-width="0.6" />
                                <path d="M20.625 17.9461H1.37498C1.03329 17.9461 0.756226 18.2232 0.756226 18.5649C0.756226 18.9065 1.03329 19.1836 1.37498 19.1836H20.625C20.9667 19.1836 21.2437 18.9065 21.2437 18.5649C21.2437 18.2232 20.9667 17.9461 20.625 17.9461Z" fill="#313D15" stroke="#313D15" stroke-width="0.6" />
                            </svg>

                            <Link to={"/webinar-management"} className="ms-2">Webinar Management</Link>
                        </li>
                        {/* <Link className={(LocationURL === "/user-management" || LocationEditURL === "/user-management") ? "active" : ""} to="/user-management">Pincodes</Link> */}

                        {/* <li className="ms-1 my-4"> */}
                            <li className={LocationURL ==="/user-profile"?"active  my-4":" my-4"}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1_573)">
                                    <path d="M10.9999 21.0833C5.4371 21.0833 0.916626 16.5628 0.916626 11C0.916626 5.43716 5.4371 0.916687 10.9999 0.916687C16.5628 0.916687 21.0833 5.43716 21.0833 11C21.0833 16.5628 16.5628 21.0833 10.9999 21.0833ZM10.9999 2.51215C6.31992 2.51215 2.51209 6.31998 2.51209 11C2.51209 15.68 6.31992 19.4879 10.9999 19.4879C15.68 19.4879 19.4878 15.68 19.4878 11C19.4878 6.31998 15.68 2.51215 10.9999 2.51215Z" fill="#313D15" />
                                    <path d="M4.17153 17.4032L2.88452 16.4565C4.7778 13.8931 7.80918 12.3721 10.9895 12.3721C14.1698 12.3721 17.1054 13.8293 19.02 16.3608L17.7436 17.3287C16.1269 15.1908 13.6592 13.9676 10.9788 13.9676C8.29845 13.9676 5.74572 15.2546 4.15025 17.4032H4.17153Z" fill="#313D15" />
                                    <path d="M11.0001 13.9677C8.8941 13.9677 7.18164 12.2552 7.18164 10.1492C7.18164 8.04321 8.8941 6.33075 11.0001 6.33075C13.1061 6.33075 14.8186 8.04321 14.8186 10.1492C14.8186 12.2552 13.1061 13.9677 11.0001 13.9677ZM11.0001 7.93685C9.77692 7.93685 8.7771 8.93667 8.7771 10.1599C8.7771 11.383 9.77692 12.3829 11.0001 12.3829C12.2233 12.3829 13.2231 11.383 13.2231 10.1599C13.2231 8.93667 12.2233 7.93685 11.0001 7.93685Z" fill="#313D15" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_573">
                                        <rect width="22" height="22" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <Link to={"/user-profile"} className="ms-2">User Profile</Link>
                        </li>
                    </ul>
                </div>
                <div className="fixed-bottom" style={{ width: "200px" }}>

                    <li className="my-4 ms-4">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1_339)">
                                <path d="M8.3001 20.8542C9.55364 20.8542 10.5642 20.8542 11.3588 20.7474C12.1839 20.6364 12.8787 20.3991 13.4304 19.8474C13.9116 19.3662 14.1548 18.7748 14.2824 18.08C14.4064 17.4048 14.4301 16.5786 14.4357 15.5872C14.4378 15.2075 14.1317 14.898 13.752 14.8958C13.3723 14.8937 13.0628 15.1998 13.0607 15.5795C13.0551 16.5819 13.0291 17.2924 12.93 17.8316C12.8346 18.3512 12.6813 18.6519 12.4582 18.8751C12.2045 19.1288 11.8482 19.2942 11.1757 19.3846C10.4832 19.4777 9.56555 19.4792 8.24977 19.4792H7.3331C6.01732 19.4792 5.09965 19.4777 4.4072 19.3846C3.73455 19.2942 3.37842 19.1288 3.12469 18.8751C2.87095 18.6214 2.70559 18.2652 2.61511 17.5926C2.52207 16.9002 2.5206 15.9825 2.5206 14.6667V7.33334C2.5206 6.01755 2.52207 5.09988 2.61511 4.40743C2.70559 3.73478 2.87095 3.37865 3.12469 3.12492C3.37842 2.87119 3.73455 2.70582 4.4072 2.61535C5.09965 2.5223 6.01732 2.52084 7.3331 2.52084H8.24977C9.56555 2.52084 10.4832 2.5223 11.1757 2.61535C11.8482 2.70582 12.2045 2.87119 12.4582 3.12492C12.6813 3.34813 12.8346 3.6488 12.93 4.16836C13.0291 4.70764 13.0551 5.41815 13.0607 6.42052C13.0628 6.8002 13.3723 7.10628 13.752 7.10417C14.1317 7.10206 14.4378 6.7925 14.4357 6.41282C14.4301 5.42145 14.4064 4.59516 14.2824 3.92004C14.1548 3.2252 13.9116 2.63386 13.4304 2.15261C12.8787 1.60087 12.1839 1.36355 11.3588 1.25263C10.5642 1.14584 9.55364 1.14584 8.3001 1.14584H7.28278C6.02915 1.14584 5.0187 1.14584 4.22395 1.25263C3.39886 1.36355 2.70421 1.60087 2.15238 2.15261C1.60064 2.70445 1.36331 3.3991 1.2524 4.22419C1.14551 5.01894 1.1456 6.02938 1.1456 7.28301V14.717C1.1456 15.9706 1.14551 16.9811 1.2524 17.7758C1.36331 18.6009 1.60064 19.2956 2.15238 19.8474C2.70421 20.3991 3.39886 20.6364 4.22395 20.7474C5.0187 20.8542 6.02915 20.8542 7.28278 20.8542H8.3001Z" fill="#313D15" stroke="#313D15" stroke-width="0.4" />
                                <path d="M8.24996 11.6875C7.87028 11.6875 7.56246 11.3797 7.56246 11C7.56246 10.6203 7.87028 10.3125 8.24996 10.3125L18.3081 10.3125L16.5109 8.77195C16.2226 8.52491 16.1892 8.09087 16.4363 7.80258C16.6834 7.51429 17.1174 7.48092 17.4057 7.72805L20.614 10.4781C20.7664 10.6086 20.8541 10.7993 20.8541 11C20.8541 11.2007 20.7664 11.3914 20.614 11.522L17.4057 14.272C17.1174 14.5191 16.6834 14.4857 16.4363 14.1974C16.1892 13.9091 16.2226 13.4751 16.5109 13.228L18.3081 11.6875L8.24996 11.6875Z" fill="#313D15" stroke="#313D15" stroke-width="0.4" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_339">
                                    <rect width="22" height="22" fill="white" transform="matrix(-1 0 0 -1 22 22)" />
                                </clipPath>
                            </defs>
                        </svg>

                        <Link className="ms-2" onClick={openModal}>Logout</Link>
                    </li>
                </div>
            </div>
        </div>
        {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}

    </section>)
}

export default Sidebar;