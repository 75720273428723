export const ActionTypes = {
    IS_LOGIN: "IS_LOGIN",
    GET_USER_LIST:"GET_USER_LIST",
    CLEAR_STORE: 'CLEAR_STORE',
    GET_ACCESSTOKEN:"GET_ACCESSTOKEN",
    GET_WEBINAR_LIST:"GET_WEBINAR_LIST",
    GET_BOOKING_LIST:"GET_BOOKING_LIST",
    LOGIN_USER_DETAIL:"LOGIN_USER_DETAIL",
    EDIT_USER_LIST:"EDIT_USER_LIST",
    CLEAR_FORM_SET_FUCT: "CLEAR_FORM_SET_FUCT",
    ADD_BOOKING_LIST:"ADD_BOOKING_LIST",
    ADD_WEBINAR_LIST:"ADD_WEBINAR_LIST",
    ADD_SINGLE_WEBINAR_LIST:"ADD_SINGLE_WEBINAR_LIST",
    GET_SEARCH_LIST :"GET_SEARCH_LIST",
    UPDATE_WEBINAR_LIST:"UPDATE_WEBINAR_LIST",
    UPDATE_WEBINAR_STATUS:"UPDATE_WEBINAR_STATUS",
    GET_ORDER_SEARCH:"GET_ORDER_SEARCH"
}

export const checkLogin = (status) => {
    return {
        type: ActionTypes.IS_LOGIN,
        payload: status,
    }
}

export const clearStore = () => ({
    type: ActionTypes.CLEAR_STORE
});

export const getUserList = (list) => {
    return {
        type: ActionTypes.GET_USER_LIST,
        payload: list,
    }
}

export const editUserList = (date_) => {
    return {
        type: ActionTypes.EDIT_USER_LIST,
        payload: date_,
    }
}

export const getWebinarList = (list) => {
    return {
        type: ActionTypes.GET_WEBINAR_LIST,
        payload: list,
    }
}

export const getBookingList = (list) => {
    return {
        type: ActionTypes.GET_BOOKING_LIST,
        payload: list,
    }
}

export const loginUserDetail = (list) => {
    return {
        type: ActionTypes.LOGIN_USER_DETAIL,
        payload: list,
    }
}

export const getAccessToken = (token) => {
    return {
        type: ActionTypes.GET_ACCESSTOKEN,
        payload: token,
    }
}

export const ClearFormSetFutios = (date_) => {
    return {
        type: ActionTypes.CLEAR_FORM_SET_FUCT,
        payload: date_,
    }
}

export const addBookingList = (list) => {
    return {
        type: ActionTypes.ADD_BOOKING_LIST,
        payload: list,
    }
}

export const addWebinarList = (list) => {
    return {
        type: ActionTypes.ADD_WEBINAR_LIST,
        payload: list,
    }
}

export const getSinleWebinarList = (list) => {
    return {
        type: ActionTypes.ADD_SINGLE_WEBINAR_LIST,
        payload: list,
    }
}

export const getOnsearch = (list) =>{
    return {
        type: ActionTypes.GET_SEARCH_LIST,
        payload: list,
    }
}

export const editWebinarList = (date_) => {
    return {
        type: ActionTypes.UPDATE_WEBINAR_LIST,
        payload: date_,
    }
}

export const UpdateWebinarStatus = (date_) => {
    return {
        type: ActionTypes.UPDATE_WEBINAR_STATUS,
        payload: date_,
    }
}

export const orderSearch = (list) =>{
    return {
        type: ActionTypes.GET_ORDER_SEARCH,
        payload: list,
    }
}