
import { ClearFormSetFutios, UpdateWebinarStatus, addBookingList, addWebinarList, editUserList, editWebinarList, getBookingList, getSinleWebinarList, getUserList, getWebinarList, loginUserDetail } from "../../redux/actions/adminAction";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ws_response = ({ evt, navigate }) => {
    // const token = localStorage.getItem("token")
    // update_webinar_status
    return (async (dispatch, getState) => {
        // const { device_id, accessToken } = getState()?.adminReducers;
        const currentUrl = window.location.pathname;
        const params = currentUrl.split('/');
        const id = params[params.length - 1];
        const ws_onmessage = JSON.parse(evt.data);
        if (ws_onmessage?.status === 401 && ws_onmessage?.message === "unauthorized access") {
            navigate('/login')
            localStorage.removeItem("token")
            dispatch(ClearFormSetFutios({
                url: "unauthorized access",
                action: ws_onmessage?.status
            }));
            return
        }
        switch (ws_onmessage.url) {
            case "GETALLUSERS":
                if (ws_onmessage?.status === 200) {
                    dispatch(getUserList(ws_onmessage?.data));
                }
                break;
            case "create_user":
                if (ws_onmessage?.status === 200) {
                    dispatch(getUserList(ws_onmessage?.data));
                }
                break;
            case "update_user":
                if (ws_onmessage?.status === 200) {
                    dispatch(getUserList(ws_onmessage?.data));
                }
                break;
            case "get_all_webinar":
                if (ws_onmessage?.status === 200) {
                    dispatch(getWebinarList(ws_onmessage?.data));
                } else {
                    // dispatch(getCategoryList(ws_onmessage?.response?.data));
                }
                break
            case "get_all_booking":
                if (ws_onmessage?.status === 200) {
                    dispatch(getBookingList(ws_onmessage?.data));
                } else {
                    // dispatch(getCategoryList(ws_onmessage?.response?.data));
                }
                break;
            case "UPDATEUSER":
                if (ws_onmessage?.status === 200) {
                    dispatch(editUserList(ws_onmessage?.data));
                    if (ws_onmessage?.data?.user_type === "admin") {
                        dispatch(loginUserDetail(ws_onmessage?.data))
                    }
                    if (ws_onmessage?.status === 200) {
                        dispatch(ClearFormSetFutios({
                            url: "UPDATEUSER",
                            action: ws_onmessage?.status
                        }));
                        toast.success(ws_onmessage.message);
                    }
                } else {
                    //ee
                };
                break;
            case "add_booking":
                if (ws_onmessage?.status === 200) {
                    dispatch(addBookingList(ws_onmessage?.data));
                    if (ws_onmessage?.status === 200) {
                        dispatch(ClearFormSetFutios({
                            url: "add_booking",
                            action: ws_onmessage?.status
                        }));
                        toast.success(ws_onmessage.message);
                    }
                } else {
                    if (ws_onmessage?.status === 201) {

                        dispatch(ClearFormSetFutios({
                            url: "add_booking",
                            action: ws_onmessage?.status
                        }));
                        toast.success(ws_onmessage.message);
                    } else {
                        dispatch(ClearFormSetFutios({
                            url: "add_booking",
                            action: ws_onmessage?.status
                        }));
                        toast.error(ws_onmessage.message);
                    }
                };
                break;
            case "ADDWEBINAR":
                if (ws_onmessage?.status === 200) {
                    dispatch(addWebinarList(ws_onmessage?.data));
                    if (ws_onmessage?.status === 200) {
                        dispatch(ClearFormSetFutios({
                            url: "ADDWEBINAR",
                            action: ws_onmessage?.status
                        }));
                        toast.success(ws_onmessage.message);
                    }
                } else {
                    dispatch(ClearFormSetFutios({
                        url: "ADDWEBINAR",
                        action: ws_onmessage?.status
                    }));
                    toast.error(ws_onmessage.message);
                };
                break;
            case "update_webinar":
                if (ws_onmessage?.status === 200) {
                    dispatch(editWebinarList(ws_onmessage));
                    if (ws_onmessage?.status === 200) {
                        dispatch(ClearFormSetFutios({
                            url: "update_webinar",
                            action: ws_onmessage?.status
                        }));
                        toast.success(ws_onmessage.message);
                    }
                } else {
                    //ee
                };
                break;

            case "get_single_webinar":
                if (ws_onmessage.status === 200) {
                    dispatch(getSinleWebinarList(ws_onmessage))
                }
                break
            case "refresh_booking":
                if (ws_onmessage.status === 200 && ws_onmessage.data.id === parseInt(id)) {
                    dispatch(getSinleWebinarList(ws_onmessage))
                }
                break
            case "update_booking": {
                if (ws_onmessage?.status === 200) {
                    dispatch(ClearFormSetFutios({
                        url: "update_webinar",
                        action: ws_onmessage?.status
                    }));
                }
            }
                break
                case "update_webinar_status": {
                    if (ws_onmessage?.status === 200) {
                        dispatch(UpdateWebinarStatus({
                            url: "update_webinar_status",
                            action: ws_onmessage?.status
                        }));
                    }
                }
                break
            default:
                return;
        }

    })
}

