import { ActionTypes, UpdateWinarStatus, orderSearch } from "../actions/adminAction";

const initialData = {
    accessToken: "",
    userList: {
        list: []
    },
    webinarList: {
        list: []
    },
    bookingList: {
        list: []
    },
    loginUserDetail: {
        list: []
    },
    ClearFormSet: {
        url: "",
        action: false
    },
    UpdateWebinarStatus: {
        url: "",
        action: false
    },
    singleWebinarList: {
        list: [],
        booking: [],
        order: []
    },
    search: "",
    orderSearch: ""
};

export const adminReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.IS_LOGIN:
            return {
                ...state,
                is_login: action.payload
            };
        case ActionTypes.GET_USER_LIST:
            return {
                ...state,
                userList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload
                }
            };
        case ActionTypes.GET_WEBINAR_LIST:
            return {
                ...state,
                webinarList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload
                }
            };
        case ActionTypes.GET_BOOKING_LIST:
            return {
                ...state,
                bookingList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload
                }
            };
        case ActionTypes.LOGIN_USER_DETAIL:
            return {
                ...state,
                loginUserDetail: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload
                }
            };
        case ActionTypes.EDIT_USER_LIST:
            const Olduserlist = [...state.userList.list];
            const UpdateUserList = Olduserlist.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        full_name: action.payload.full_name,
                        password: action.payload.password,
                    };
                } else
                    return elm;
            });
            return {
                ...state,
                userList: {
                    ...state.userList,
                    list: UpdateUserList,
                }
            };
        case ActionTypes.CLEAR_STORE:
            return initialData;

        case ActionTypes.GET_ACCESSTOKEN:
            return {
                ...state,
                accessToken: action.payload,
            };
        case ActionTypes.CLEAR_FORM_SET_FUCT:
            return {
                ...state,
                ClearFormSet: {
                    url: action?.payload?.url,
                    action: action?.payload?.action
                }
            };
        case ActionTypes.UPDATE_WEBINAR_STATUS:
            return {
                ...state,
                UpdateWinarStatus: {
                    url: action?.payload?.url,
                    action: action?.payload?.action
                }
            };
        case ActionTypes.ADD_BOOKING_LIST:
            const OldBookinglist = [...state.bookingList.list];
            OldBookinglist.unshift(action.payload);
            return {
                ...state,
                bookingList: {
                    ...state.bookingList,
                    list: OldBookinglist,
                },
            };
        case ActionTypes.ADD_WEBINAR_LIST:
            const newWebinarList = [action.payload, ...state.webinarList.list];
            return {
                ...state,
                webinarList: {
                    ...state.webinarList,
                    list: newWebinarList,
                },
            };
        case ActionTypes.ADD_SINGLE_WEBINAR_LIST:
            return {
                ...state,
                singleWebinarList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    booking: Object.keys(action.payload).length === 0 ? [] : action.payload.booking,
                    order: Object.keys(action.payload).length === 0 ? [] : action.payload.order
                }
            };
        case ActionTypes.GET_SEARCH_LIST:
            return {
                ...state,
                search: action.payload,
            };
        case ActionTypes.GET_ORDER_SEARCH:
            return {
                ...state,
                orderSearch: action.payload,
            };
        case ActionTypes.UPDATE_WEBINAR_LIST:
            const OldSigleWebinarlistx = Array.isArray(state.webinarList.list) ? [...state.webinarList.list] : [];
            const UpdateWebinarlist = OldSigleWebinarlistx.map((elm) => {
                if (elm?.id === action.payload.data.id) {
                    return {
                        ...elm,
                        webinar_title: action?.payload?.data?.webinar_title,
                        ticket_size: action?.payload?.data?.ticket_size,
                        ticket_price: action?.payload?.data?.ticket_price,
                        description: action?.payload?.data?.description,
                        product_price: action?.payload?.data?.product_price,
                        offer: action?.payload?.data?.offer,
                        webinar_image: action?.payload?.data?.webinar_image,
                        product_images: [...action?.payload?.data?.product_images],
                        userId: action?.payload?.data?.userId,
                        webinar_url: action?.payload?.data?.webinar_url,
                        completed_date: action?.payload?.data?.completed_date,
                        winner_number: action?.payload?.data?.winner_number,
                        available_seats: action?.payload?.data?.available_seats
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                webinarList: {
                    ...state.webinarList,
                    list: UpdateWebinarlist,
                },
            };
        default:
            return state;
    }
};
