
/* eslint-disable */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { checkLogin, clearRedux } from '../redux/actions/adminActions';
import { ws_response } from './webSocketResponse';
import { useNavigate } from 'react-router-dom';

const useWebSocket = (url, functions, accessToken) => {
  const Dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(() => {
    if(accessToken !== "" && accessToken !== null ) {
      function connectWebSocket() {
          const socket = new WebSocket(url);
          functions(socket);
          // Set up event handlers
          socket.onmessage = function (evt) {
              Dispatch(ws_response({evt:evt, ws:socket ,navigate:navigate}));
          };
          socket.onclose = (event) => {
              console.log("websocket close")
              // Reconnect after a delay
              if(localStorage.getItem("token") !== null) {
                setTimeout(() => {
                    console.log("Reconnecting...");
                    connectWebSocket();
                }, 1000); 
              } else {
                  localStorage.clear();
                  localStorage.removeItem("token");
              }
          };
          return () => {
            socket.close();
          };
      };
      
      // Initial connection
      connectWebSocket();
    }
  }, [url]);
};

export default useWebSocket;