import './App.css';
import Layout from './components/Layout/layout';
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Login from './components/Login/login';
import './assets/css/style.css'
import UserManagement from './components/UserManagement/userManagement';
import UserDetail from './components/UserManagement/userDetail';
import WebinarManagement from './components/WebinarManagement/webinarManagement';
import ProfileInformation from './components/ProfileInformation/profileInformation';
import WebinarDetail from './components/WebinarManagement/webinarDetail';
import useWebSocket from './components/Websocket/useWebSocket';
import React ,{useState,createContext, useEffect} from 'react';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import {  ClearFormSetFutios, getAccessToken } from './redux/actions/adminAction';
import { useDispatch, useSelector } from 'react-redux';
import ResetPassword from './components/Login/resetPassord';
import ResetPasswordDetail from './components/Login/resetPasswordDetail';
export const WebSocketContext = createContext();
function App() {
  const [ GetWEBSocket, setGetWEBSocket ] = useState("");
  const Dispatch = useDispatch()
  const { accessToken} = useSelector((state) => state.adminReducers);
  const navigate = useNavigate()
  
  useWebSocket(`${process.env.REACT_APP_SERVICE_URL}${accessToken}/`, setGetWEBSocket,localStorage.getItem("token"));
  
  useEffect(() => {
    if(localStorage.getItem("token") === null ) {
      Dispatch(getAccessToken(""));
    } else {
      Dispatch(getAccessToken(localStorage.getItem("token")));
    };
  }, [accessToken]);

  
  // useEffect(() => {
  //   if(ClearCache === undefined) {
  //     localStorage.clear();
  //   };
  // }, [ ClearCache ]);

  return (<WebSocketContext.Provider value={{websocket:GetWEBSocket}}>
    <div className="App">
      {/* <BrowserRouter> */}
    <Routes>
      <Route path="/login" element={ <Login RouteName="login" />} />
      <Route path="/reset-password" element={ <ResetPassword RouteName="reset-password" />} />
      <Route path="/reset-password/:id" element={ <ResetPasswordDetail RouteName="reset-password" />} />
      <Route path="/login" element={accessToken === "" ? <Login /> : <Navigate  replace to={'/user-management'}/>} >
                <Route path="/login" element={accessToken === "" ? <Login /> : <Navigate  replace to={'/user-management'}/>} />
      </Route>
      <Route path="/user-management" element={accessToken !== "" ? <Layout><UserManagement RouteName="/user-management" /></Layout>:<Navigate replace to ="login"/>} />
      <Route path="/edit-user/:id" element={accessToken !== "" ? <Layout><UserDetail RouteName="/user-management" /></Layout>:<Navigate replace to ="login"/>} />
      <Route path="/webinar-management" element={accessToken !== "" ?<Layout><WebinarManagement RouteName="/webinar-management" /></Layout>:<Navigate replace to ="login"/>} />
      <Route path="/add-webinar" element={accessToken !== ""?<Layout><WebinarDetail RouteName="/add-webinar" /></Layout>:<Navigate replace to ="login"/>} />
      <Route path="/edit-webinar/:id" element={accessToken!==""?<Layout><WebinarDetail RouteName="/add-webinar" /></Layout>:<Navigate replace to ="login"/>} />
      <Route path="/user-profile" element={accessToken!==""?<Layout><ProfileInformation RouteName="/user-profile" /></Layout>:<Navigate replace to ="login"/>} />
      {/* <Route path="/dashboard" element={ <Layout/>} /> */}
      <Route path="*" element={<Navigate to="/login"/>} />
    </Routes>
      {/* <Redirect from="/" to="/admin/dashboard" />
    </Switch> */}
  {/* </BrowserRouter> */}
  <ToastContainer />
    </div>
    </WebSocketContext.Provider> );
}

export default App;
