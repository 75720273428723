import { useContext, useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap"
import { WebSocketContext } from "../../App";
import wsSend_request from "../Websocket/webSocketRequest";
import { useDispatch, useSelector } from "react-redux";
import { ClearFormSetFutios, clearStore, getAccessToken } from "../../redux/actions/adminAction";
import { useCallback } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CommonewModel = (props) => {
    const { showModalNew, setShowModalNew } = props;

    const [addBookingstate, setBooking] = useState({
        ownerName: "",
        email: ""
    })
    const dispatch = useDispatch()
    const { websocket } = useContext(WebSocketContext);
    const { ClearFormSet, loginUserDetail } = useSelector(state => state.adminReducers);
    const [winner_number, setWinnerNumber] = useState("")
    const navigate = useNavigate()
    const handleClose = useCallback(() => {
        setShowModalNew({ ...showModalNew, open: false });
    }, [showModalNew, setShowModalNew]);

    useEffect(() => {
        if (ClearFormSet?.action === 200 && ClearFormSet?.url === "add_booking") {
            handleClose();
            dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        }
    }, [ClearFormSet, showModalNew?.Data?.webinarId, dispatch, handleClose]);

    function addBooking() {
        let param = {
            "type": "add_booking",
            "payload": {
                serialNumbers: showModalNew?.Data?.serialNumbers,
                userId: showModalNew?.Data?.userId,
                webinarId: showModalNew?.Data?.webinarId,
                ownerName: addBookingstate?.ownerName,
                email: addBookingstate?.email,
                payment_type:"Admin Book"
                // status:"hold"
            }
        };
        wsSend_request(websocket, param);
    }

    function getAllWebinar() {
        let param = {
            "type": "get_all_webinar",
            "payload": {}
        };
        wsSend_request(websocket, param);
    }
    // useEffect(() => {
    //     let param = {
    //       "type": "get_all_webinar",
    //       "payload": {}
    //     };
    //     wsSend_request(websocket, param);
    //   }, [websocket]);

    function addWinnerNumber() {
        let param = {
            "type": "update_webinar",
            "payload": {
                webinarId: showModalNew?.Data?.id,
                winner_number: winner_number,
                completed_date: new Date()
            }
        };
        wsSend_request(websocket, param);
        handleClose()
        getAllWebinar()
        toast.success("winner number added sucessfully")
    }

    function logout() {
        if (loginUserDetail) {
            const token = localStorage.getItem("token")
            axios.post(`${process.env.REACT_APP_BASE_URL}api/userlogout`, {
                token: token,
            }).then((resp) => {
                toast.success(resp.data.message)
                dispatch(getAccessToken(""))
                localStorage.clear()
                localStorage.removeItem("token")
                navigate('/login')
            }).catch((err)=>{
                if(err){
                    localStorage.removeItem("token")
                    dispatch(getAccessToken(""));
                    dispatch(clearStore(""))
                    navigate('/login')
                }
            })
        }
    }
    return (
        <Modal show={showModalNew?.open} className={showModalNew?.modalType === "log-out" ? "commonModel" : showModalNew?.modalType === "log-out" ? "common-model-add-winner" : ""}>
            {showModalNew?.modalType === "log-out" && <div className="text-center">
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3334 8.61048C13.9431 8.58013 14.7603 8.7306 15.3409 8.91992L30.0076 13.5515C32.6855 14.3972 34.6667 16.8655 34.6667 20V48.7228C34.6667 52.4047 31.6819 55.3895 28 55.3895C27.4552 55.3895 26.5201 55.2118 25.9925 55.0801L11.3258 50.4485C8.64792 49.6028 6.66669 47.1022 6.66669 44V15.2771C6.66669 11.5952 9.65146 8.61048 13.3334 8.61048ZM51.6095 21.7238L56.9428 27.0572C57.3334 27.4452 57.587 28.2303 56.9428 28.9428L51.6095 34.2761C51.0888 34.7968 50.2446 34.7968 49.7239 34.2761C49.2432 33.7955 49.1708 32.9574 49.7239 32.3905L52.78 29.3333H44V26.6667H52.78L49.7239 23.6095C49.1582 23.0438 49.2432 22.2045 49.7239 21.7238C50.2045 21.2432 51.0333 21.1613 51.6095 21.7238Z" fill="#AEDE3E" />
                    <path d="M37.3334 6.66663C40.9184 6.66663 44 9.55244 44 13.3333V42.6666C44 46.2516 41.1396 49.3342 37.3334 49.3342H34.639C34.6573 49.1329 34.6667 48.9289 34.6667 48.7228V19.9087C34.6667 17.0002 32.7811 14.4273 30.0076 13.5515L15.3409 8.9199C14.5588 8.70528 14.0144 8.61045 13.3334 8.61045C9.74835 8.61045 6.82426 11.4402 6.67285 14.9879L6.66669 13.3333C6.66669 9.74829 9.51555 6.66663 13.3334 6.66663H37.3334ZM26.6667 32C27.4031 32 28 32.5969 28 33.3333C28 34.0171 27.5095 34.6666 26.6667 34.6666H22.6667C21.9303 34.6666 21.3334 34.0697 21.3334 33.3333C21.3334 32.6495 21.8665 32 22.6667 32H26.6667Z" fill="#8EA066" />
                </svg>
            </div>}
            {showModalNew?.modalType === "log-out" && <span className="log-out-text text-center ">Log Out</span>}
            {showModalNew?.modalType === "log-out" && <span className="log-out-footer text-center">Are you sure want to log out?</span>}
            {showModalNew?.modalType === "log-out" && <Modal.Footer>
                <div>
                    <button className="ps-4 pe-4 pt-2 pb-2 mx-2" style={{ backgroundColor: "#E6E8DA", border: "unset", borderRadius: "7px" }} onClick={handleClose} >Cancel</button>
                    <button className="ps-4 pe-4 pt-2 pb-2" style={{ backgroundColor: "#AEDE3E", border: "unset", borderRadius: "7px" }} onClick={logout}>Log Out </button>
                </div>
            </Modal.Footer>}
            {showModalNew?.modalType === "add-winner-number" && <Modal.Header>
                <Modal.Title>{showModalNew?.Data?.title}</Modal.Title>
            </Modal.Header>}
            {showModalNew?.modalType === "add-winner-number" && <Modal.Body>
                <Form.Group className="mb-2" controlId="formGroupEmail">
                    <Form.Control
                        type="text"
                        placeholder="Add Winner Number"
                        onChange={(e) => setWinnerNumber(e.target.value)}
                        value={winner_number}
                    />
                </Form.Group>
            </Modal.Body>}
            {showModalNew?.modalType === "add-winner-number" && <Modal.Footer>
                <div>
                    <button className="ps-4 pe-4 pt-2 pb-2 mx-2" style={{ backgroundColor: "#E6E8DA", border: "unset", borderRadius: "7px" }} onClick={handleClose} >Cancel</button>
                    <button className="ps-4 pe-4 pt-2 pb-2" style={{ backgroundColor: "#AEDE3E", border: "unset", borderRadius: "7px" }} onClick={addWinnerNumber}>Submit </button>
                </div>
            </Modal.Footer>}

            {showModalNew?.modalType === "add-booking-name" && <Modal.Header>
                <span style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                    <span style={{ fontFamily: "M PLUS 1", fontWeight: "600", fontSize: "25px", color: "#313D15", textOverflow: "ellipsis" }}>{showModalNew?.Data?.serialNumbers && `Book This Seats (${showModalNew.Data.serialNumbers})`}</span>
                </span>            </Modal.Header>}
            {showModalNew?.modalType === "add-booking-name" && <Modal.Body>
                <Form.Group className="mb-2" controlId="formGroupEmail">
                    <Form.Control
                        type="text"
                        placeholder="Name Of Owner"
                        onChange={(e) => setBooking({ ...addBookingstate, ownerName: e.target.value })}
                        value={addBookingstate?.ownerName}
                    />
                </Form.Group>
                <Form.Group className="mb-2" controlId="formGroupEmail">
                    <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        onChange={(e) => setBooking({ ...addBookingstate, email: e.target.value })}
                        value={addBookingstate?.email}
                    />
                </Form.Group>
            </Modal.Body>}
            {showModalNew?.modalType === "add-booking-name" && <Modal.Footer>
                <div>
                    <button className="ps-4 pe-4 pt-2 pb-2 mx-2" style={{ backgroundColor: "#E6E8DA", border: "unset", borderRadius: "7px" }} onClick={handleClose} >Cancel</button>
                    <button className="ps-4 pe-4 pt-2 pb-2" style={{ backgroundColor: "#AEDE3E", border: "unset", borderRadius: "7px" }} onClick={() => addBooking()} disabled={!addBookingstate?.ownerName} >Submit </button>
                </div>
            </Modal.Footer>}

        </Modal>
    )
}

export default CommonewModel;