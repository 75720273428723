import React, { useContext, useEffect, useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { WebSocketContext } from "../../App";
import wsSend_request from "../Websocket/webSocketRequest";
import EmptySpaceFieldValid from "../Validation/EmptySpaceValid";
import CheckValid from "../Validation/ErrorValidation";
import { ClearFormSetFutios } from "../../redux/actions/adminAction";


const UserDetail = () => {
    const id = parseInt(useParams()?.id);
    const { websocket } = useContext(WebSocketContext);

    const { userList,ClearFormSet } = useSelector(state => state.adminReducers);
    const [userData, setUserData] = useState([]);
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const [PasswordShow, setPasswordShow] = useState(false);

    const [userState, setUserState] = useState({
        full_name: "",
        email: "",
        password: "",
        mobile_number: ""
    });

    const [error, setError] = useState("");
    const [full_name_error, setFullNameError] = useState("")
    const [emailerror, setEmailError] = useState("")
    const [password_error, setPasswordError] = useState("")
    const [mobile_number_error, setMobileEror] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        if (id) {
            const user = userList.list?.filter((user) => user.id === id);
            setUserData(user[0]);
        }
    }, [id, userList.list]);

    useEffect(() => {
        if (LocationRoute === "edit-user") {
            setUserState(prevState => ({
                ...prevState,
                full_name: userData?.full_name,
                email: userData?.email,
                // password: userData?.password,
                mobile_number: userData?.mobile_number
            }));
        }
    }, [userData, LocationRoute, setUserState]);


    function validatePassword(password) {
        // Regular expression to validate password
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return passwordRegex.test(password);
    }

    function UpdateProfile(e) {
        e.preventDefault();

        if (!validatePassword(userState.password)) {
            setError("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number.");
            return;
        }

        if (userState.full_name !== "" && userState.email !== "" && userState.mobile_number !== "" && userState.confirmPassword !== "") {
            let param = {
                "type": "UPDATEUSER",
                "payload": {
                    "id": userData?.id,
                    "full_name": userState?.full_name,
                    "email":userState?.email,
                    "mobile_number": userState?.mobile_number,
                    "newPassword": userState?.password
                }
            };
            wsSend_request(websocket, param);
        } else {
            CheckValid(userState?.full_name, { type: 'fullname', error: setFullNameError });
            CheckValid(userState?.email, { type: 'email', error: setEmailError });
            CheckValid(userState?.mobile_number, { type: 'mobile', error: setMobileEror });
            CheckValid(userState?.password, { type: 'password', error: setEmailError });
        }
    }

    useEffect(()=>{
        if (ClearFormSet?.action === 200 && ClearFormSet?.url === "UPDATEUSER") {
            navigate("/user-management")
            setTimeout(()=>{
                dispatch(ClearFormSetFutios({
                    url:"",
                    action:""
                }))
            },1000)
        }
    },[ClearFormSet])

    return (
        <>
            <div className="p-4">
                <div className="row">
                    <div className="col-md-12 border-bottom">
                        <p>Set Username</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12 col-sm-12 col-lg-12 col-md-12">
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                            <Form>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                    <Form.Label column sm="3">
                                        Full name
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Full Name"
                                            onChange={(e) => setUserState({ ...userState, full_name: e.target.value })}
                                            value={userState?.full_name}
                                            onKeyDown={EmptySpaceFieldValid}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'fullname', error: setFullNameError })}
                                        />
                                        {full_name_error && <span className="text-danger">{full_name_error}</span>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="email">
                                    <Form.Label column sm="3">
                                        Email
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            type="email"
                                            placeholder="Email Address"
                                            onChange={(e) => setUserState({ ...userState, email: e.target.value })}
                                            value={userState?.email}
                                            onKeyDown={EmptySpaceFieldValid}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'email', error: setEmailError })}

                                        />
                                        {emailerror && <span className="text-danger">{emailerror}</span>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="mobile">
                                    <Form.Label column sm="3">
                                        Mobile number
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Mobile Number"
                                            onChange={(e) => setUserState({ ...userState, mobile_number: e.target.value.replace(/\D/g, '') })}
                                            value={userState?.mobile_number}
                                            onKeyDown={EmptySpaceFieldValid}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'mobile', error: setMobileEror })}
                                        />
                                        {mobile_number_error && <span className="text-danger">{mobile_number_error}</span>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                    <Form.Label column sm="3">
                                        Change Password
                                    </Form.Label>
                                    <Col sm="9">
                                        <span >
                                            <Form.Control
                                                type={PasswordShow ? "text" : "password"}
                                                placeholder="Password"
                                                onChange={(e) => setUserState({ ...userState, password: e.target.value })}
                                                value={userState?.password}
                                                onKeyDown={EmptySpaceFieldValid}
                                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'password', error: setPasswordError })}

                                            />
                                            <span className="showpassbtn mb-3" onClick={() => setPasswordShow(!PasswordShow)}>
                                                {PasswordShow ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>) :
                                                    (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>)}
                                            </span>
                                        </span>
                                        {password_error && <span className="text-danger mt-3">{password_error}</span>}
                                        {error && <span className="text-danger">{error}</span>}
                                    </Col>
                                </Form.Group>
                                <Button type="submit" className="button float-end rounded" style={{ border: "unset" }} onClick={UpdateProfile}>
                                    Save Changes
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserDetail;
