import React from "react";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sideBar";
// import { useLocation } from "react-router-dom";
const Layout = ({ children }) => {
    
    // const LocationURL = useLocation().pathname;
  return (
    <div className="container-fluid g-0">
      <section className="d-flex">
        <div style={{ width: "269px", height: "100vh" }} className="p-3">
          <Sidebar />
        </div>
        <div className="flex-grow-1">
          <div>
            <Header />
          </div>
          <div className="main  rounded layout-container" >
            <div className="mb-5">
              {children}
            </div>
            </div>
        </div>
      </section>
    </div>
  );
};

export default Layout;
