import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import loginCard from "../../assets/img/login_card_logo.png"
import Card from 'react-bootstrap/Card'
import {Form,Button,} from 'react-bootstrap'
import EmptySpaceFieldValid from "../Validation/EmptySpaceValid";
import axios from 'axios';
import { toast } from "react-toastify";

const ResetPassword = () => {

    const [loginState, setLoginState] = useState({
        Email: "",
    });

    const [emailError,setEamilError] = useState("")

    function userLogin(e) {
        e.preventDefault();
        if (loginState?.Email !== "") {
            axios.post(`${process.env.REACT_APP_BASE_URL}api/send-reset-password`, {
                email: loginState.Email,
            })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                setEamilError(error?"Please Enter Valid Email":"");
            });
        } else {
            setEamilError("Email Field Can Not Be Empty");
        }
    }
    
    return (<section className="admin-login" style={{ background: "#000000" }}>
        <Container >
            {/* <Image src={gunsot1} alt="Gunshot" /> */}
            <Row>
                <Col md={12}>
                    <Col md={4} >
                        <Card style={{ width: '22rem',backgroundColor:"white",color:"black"}} className="p-4">

                            {/* <div className="login-form"> */}
                                <div className="mt-1 text-center">
                                    <Image src={loginCard} height={70} width={70} onKeyDown={EmptySpaceFieldValid}/>
                                </div>
                                <span className="login-logo-under-text text-center">Don't worry, we'll send you an email to reset your password.</span>
                            <div className="login-form">
                                
                                <Form className="mt-2 " >
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                         type="email" 
                                        //  placeholder="Enter email"
                                         onChange={(e) => setLoginState({...loginState,Email:e.target.value})}
                                         onKeyDown={EmptySpaceFieldValid}
                                          />
                                          {emailError&&<span className="text-danger">{emailError}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3 d-flex" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Remember Me"  />
                                    </Form.Group>
                                    <div className="mt-3">
                                    <Button style={{width:"100%",border:"none"}} type="submit"  onClick={userLogin}>
                                        Reset Password
                                    </Button>
                                    </div>
                                </Form>
                            </div>
                            {/* </div> */}
                        </Card>

                    </Col>
                </Col>
            </Row>
        </Container>
    </section>)
}

export default ResetPassword;