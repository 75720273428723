import React, { useContext, useEffect, useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { WebSocketContext } from "../../App";
import wsSend_request from "../Websocket/webSocketRequest";
import EmptySpaceFieldValid from "../Validation/EmptySpaceValid";
import CheckValid from "../Validation/ErrorValidation";
import { ClearFormSetFutios } from "../../redux/actions/adminAction";
const ProfileInformation = () => {
    const LocationRoute = useLocation()?.pathname
    const { loginUserDetail,ClearFormSet } = useSelector(state => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const [ PasswordShow, setPasswordShow ] = useState(false);
    const [newPasswordShow,setNewPasswordShow] = useState(false)
    const [confirmPasswordShow,setConfirmPasswordShow] = useState(false)
    const [userState, setUserState] = useState({
        full_name: "",
        email: "",
        password: "",
        confirmPassword: "",
        newpassword: ""
    });
    const [newpassworderror, setNewPasswordError] = useState("")
    const [full_name_error, setFullNameError] = useState("")
    const [emailerror, setEmailError] = useState("")
    const [confirmPassworderror, setConfirmPasswordError] = useState("")
    const [passworderror, setPasswordError] = useState("")
    const dispatch =useDispatch()
    useEffect(() => {
        if (LocationRoute === "/user-profile") {
            setUserState((prevUserState) => ({
                ...prevUserState,
                full_name: loginUserDetail?.list?.full_name,
                email: loginUserDetail?.list?.email,
            }));
        }
    }, [LocationRoute, loginUserDetail?.list?.full_name, loginUserDetail?.list?.email, ClearFormSet, setUserState]);
    
    function UpdateProfile(e) {
        e.preventDefault()
        if (userState?.full_name !== "" && userState?.email !== "" && userState?.password !== "" && userState?.newpassword !== "" && userState?.confirmPassword !== "") {
            if (userState?.newpassword === userState?.confirmPassword) {
                let param = {
                    "type": "UPDATEUSER",
                    "payload": {
                        "id": loginUserDetail.list?.id,
                        "full_name": userState?.full_name,
                        "email":userState?.email,
                        "password": userState?.password,
                        "newPassword": userState?.confirmPassword
                    }
                };
                wsSend_request(websocket, param);
            } else {
                setNewPasswordError("new password does not match")
            }
        } else {

            CheckValid(userState?.full_name, { type: 'fullname', error: setFullNameError });
            CheckValid(userState?.email, { type: 'email', error: setEmailError });
            CheckValid(userState?.password, { type: 'password', error: setPasswordError });
            CheckValid(userState?.newpassword, { type: 'newpassword', error: setNewPasswordError });
            CheckValid(userState?.confirmPassword, { type: 'confirmpassword', error: setConfirmPasswordError });

        }
    }

    useEffect(()=>{
        if (ClearFormSet?.action === 200 && ClearFormSet?.url === "UPDATEUSER") {
            setUserState((prevUserState) => ({
                ...prevUserState,
                full_name: "",
                email: "",
                password:"",
                confirmPassword:"",
                newpassword:""
            }));
            
            setTimeout(()=>{
                dispatch(ClearFormSetFutios({
                    url:"",
                    action:""
                }))
            },1000)
        }
    },[ClearFormSet,dispatch])

    return (
        <>
            <div className="p-4">
                <div className="row">
                    <div className="col-md-12 border-bottom">
                        <p>Basic Information</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="col-10">
                            <Form>
                                <Form.Group as={Row} className="mb-3" controlId="fullname">
                                    <Form.Label column sm="3">
                                        Full name
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Full Name"
                                            onChange={(e) => setUserState({ ...userState, full_name: e.target.value })}
                                            value={userState?.full_name}
                                            onKeyDown={EmptySpaceFieldValid}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'fullname', error: setFullNameError })}
                                        />
                                        {full_name_error && <span className="text-danger">{full_name_error}</span>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="email">
                                    <Form.Label column sm="3">
                                        Email
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            type="email"
                                            placeholder="Email Address"
                                            onChange={(e) => setUserState({ ...userState, email: e.target.value })}
                                            value={userState?.email}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'email', error: setEmailError })}
                                            onKeyDown={EmptySpaceFieldValid}
                                        />
                                        {emailerror && <span className="text-danger">{emailerror}</span>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="currentpassword">
                                    <Form.Label column sm="3">
                                        Current Password
                                    </Form.Label>
                                    <Col sm="9">
                                        <span style={{position:"relative"}}>
                                        <Form.Control
                                            type={PasswordShow ? "text" : "password"}
                                            placeholder="Password"
                                            onChange={(e) => setUserState({ ...userState, password: e.target.value })}
                                            value={userState.password}
                                            onKeyDown={EmptySpaceFieldValid}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'password', error: setPasswordError })}

                                             />
                                         <span className="showpassbtn" onClick={() => setPasswordShow(!PasswordShow)}>
                                            {PasswordShow ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>) :
                                                (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>)}
                                                </span>
                                        </span>
                                        {passworderror && <span className="text-danger">{passworderror}</span>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="newpassword">
                                    <Form.Label column sm="3">
                                        New Password
                                    </Form.Label>
                                    <Col sm="9">
                                    <span style={{position:"relative"}}>
                                        <Form.Control
                                            type={newPasswordShow ? "text" : "password"}
                                            placeholder="New Password"
                                            onChange={(e) => setUserState({ ...userState, newpassword: e.target.value })}
                                            value={userState.newpassword}
                                            onKeyDown={EmptySpaceFieldValid}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'newpassword', error: setNewPasswordError })}

                                        />
                                          <span className="showpassbtn" onClick={() => setNewPasswordShow(!newPasswordShow)}>
                                            {newPasswordShow ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>) :
                                                (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>)}
                                                </span>
                                        </span>
                                        {newpassworderror && <span className="text-danger">{newpassworderror}</span>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="confirmPassword">
                                    <Form.Label column sm="3">
                                        Confirm New Password
                                    </Form.Label>
                                    <Col sm="9">
                                    <span style={{position:"relative"}}>
                                        <Form.Control
                                            type={confirmPasswordShow? "text":"password"}
                                            placeholder="Confirm New Password"
                                            onChange={(e) => setUserState({ ...userState, confirmPassword: e.target.value })}
                                            value={userState.confirmPassword}
                                            onKeyDown={EmptySpaceFieldValid}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'confirmpassword', error: setConfirmPasswordError })}

                                        />
                                        <span className="showpassbtn" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}>
                                            {confirmPasswordShow ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>) :
                                                (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>)}
                                        </span>
                                        </span>
                                        {confirmPassworderror && <span className="text-danger">{confirmPassworderror}</span>}
                                    </Col>
                                </Form.Group>

                                <Button type="submit" className="button float-end rounded" style={{ border: "unset" }} onClick={UpdateProfile}>
                                    Save Changes
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileInformation;
