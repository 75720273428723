import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import loginCard from "../../assets/img/login_card_logo.png"
import Card from 'react-bootstrap/Card'
import {Form,Button,} from 'react-bootstrap'
import {Link,useNavigate} from 'react-router-dom'
import EmptySpaceFieldValid from "../Validation/EmptySpaceValid";
import axios from 'axios';
import { useDispatch } from "react-redux";
import {  getAccessToken, loginUserDetail } from "../../redux/actions/adminAction";
import { toast } from "react-toastify";

const Login = () => {

    const [loginState, setLoginState] = useState({
        Email: "",
        Password:"",
        isRemember:false
    });
    const navigate = useNavigate();
    const [emailError,setEamilError] = useState("")
    const [passwordError,setPasswordError] = useState("")
    const dispatch = useDispatch();


    function userLogin(e) {
        e.preventDefault();
        if (loginState?.Email !== "" && loginState?.Password !== "") {
            axios.post(`${process.env.REACT_APP_BASE_URL}api/login`, {
                email: loginState.Email,
                password: loginState.Password
            })
            .then(response => {
                const { token } = response.data.data;
                const {userData} = response.data.data
                dispatch(loginUserDetail(userData))
                localStorage.setItem('token', token);
                dispatch(getAccessToken(token))
                if(userData.user_type === "admin"){
                    toast.success("user login successfully")
                    navigate('/user-management')
                }
            })
            .catch(error => {
                setEamilError(error?"Please Enter Valid Email":"");
                setPasswordError(error?"Please Enter Valid Password":"");
            });
        } else {
            setEamilError("Email Field Can Not Be Empty");
            setPasswordError("Password Field Can Not Be Empty");
        }
    }
    
    return (<section className="admin-login" style={{ background: "#000000" }}>
        <Container >
            {/* <Image src={gunsot1} alt="Gunshot" /> */}
            <Row>
                <Col md={12}>
                    <Col md={4} >
                        <Card style={{ width: '22rem',backgroundColor:"white",color:"black"}} className="p-4">

                            {/* <div className="login-form"> */}
                                <div className="mt-1 text-center">
                                    <Image src={loginCard} height={70} width={70} onKeyDown={EmptySpaceFieldValid}/>
                                </div>
                                <span className="login-logo-under-text text-center">Please enter your user information</span>
                            <div className="login-form">
                                
                                <Form className="mt-2" >
                                    <Form.Group className="mb-2" controlId="formBasicEmail">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                         type="email" 
                                        //  placeholder="Enter email"
                                         onChange={(e) => setLoginState({...loginState,Email:e.target.value})}
                                         onKeyDown={EmptySpaceFieldValid}
                                          />
                                          {emailError&&<span className="text-danger">{emailError}</span>}
                                    </Form.Group>

                                    <Form.Group className="mb-2" controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control 
                                        type="password" 
                                        // placeholder="Password"
                                        onKeyDown={EmptySpaceFieldValid} 
                                        onChange={(e)=>setLoginState({...loginState,Password:e.target.value})}
                                        // onKeyUp={}
                                        />
                                        {passwordError&&<span className="text-danger">{passwordError}</span>}
                                    </Form.Group>
                                    <Form.Group className="mb-3 d-flex" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Remember Me"  />
                                    <Link className="mt-1" id="forgot-password" to='/reset-password'>Forgot Password</Link>
                                    </Form.Group>
                                    <div>
                                    <Button style={{width:"100%",border:"none"}} type="submit"  onClick={userLogin}>
                                        Submit
                                    </Button>
                                    </div>
                                </Form>
                            </div>
                            {/* </div> */}
                        </Card>

                    </Col>
                </Col>
            </Row>
        </Container>
    </section>)
}

export default Login;