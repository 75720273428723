import React, { useContext, useEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import { WebSocketContext } from "../../App";
import wsSend_request from "../Websocket/webSocketRequest";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonewModel from "../CommonModel/commonModel";
import { toast } from "react-toastify";
import ReactPaginate from 'react-paginate';

const WebinarManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { webinarList, bookingList, ClearFormSet, search, loginUserDetail, UpdateWinarStatus } = useSelector(state => state.adminReducers);
  const navigate = useNavigate();
  
  const [filteredWebinarList, setFilteredWebinarList] = useState([]);
  const [showModalNew, setShowModalNew] = useState({ open: false, title: "", modalType: "", Data: {} });
  
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0); // Start with 0 for ReactPaginate

  const openModal = (title, id) => {
    setShowModalNew({
      open: !showModalNew?.open,
      title: "Create New Box",
      modalType: "add-winner-number",
      Data: { title: title, id: id }
    });
  };

  const GetAvailableSeats = (id) => {
    const record = bookingList?.list.filter((booking) => booking?.webinarId === id);
    const FullFillSeats = bookingList?.list.filter((booking) => booking?.webinarId === id && booking?.status === 'closed');
    return record.length - FullFillSeats.length || 0;
  };

  const GetWinnerNumber = (id) => {
    const winner_number = webinarList?.list.find((webinar) => webinar?.id === id);
    return { winner_number: winner_number?.winner_number };
  };

  const handleSwitchChange = (id, isChecked) => {
    if (loginUserDetail?.list?.user_type === "admin") {
      const param = { type: "update_webinar", payload: { webinarId: id, status: isChecked } };
      wsSend_request(websocket, param);
      toast.success(isChecked ? "Webinar Active Successfully" : "Webinar Inactive Successfully");
    }
  };

  useEffect(() => {
    if (search && webinarList) {
      const filteredData = webinarList?.list.filter(webinar =>
        webinar.webinar_title.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredWebinarList(filteredData);
      setCurrentPage(0); // Reset to first page on new search
    } else {
      setFilteredWebinarList(webinarList?.list || []);
    }
  }, [search, webinarList]);

  // useEffect(() => {
  //   wsSend_request(websocket, { type: "get_all_webinar", payload: {} });
  // }, [websocket, UpdateWinarStatus]);

  useEffect(() => {
        let param = {
          "type": "get_all_webinar",
          "payload": {}
        };
        wsSend_request(websocket, param);
      }, [websocket,UpdateWinarStatus]);
  
      useEffect(() => {
        let param = {
          "type": "get_all_booking",
          "payload": {}
        };
        wsSend_request(websocket, param);
      }, [websocket,ClearFormSet]);
      
  // useEffect(() => {
  //   wsSend_request(websocket, { type: "get_all_booking", payload: {} });
  // }, [websocket, ClearFormSet]);

  // Calculate page count
  const pageCount = Math.ceil(filteredWebinarList.length / itemsPerPage);
  const displayedWebinars = filteredWebinarList.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected); // Update current page
  };

  return (
    <div className="row">
      <div style={{ margin: "2px" }} className="fixTableHead">
        <Table responsive style={{ borderRadius: "19px", marginTop: "2px" }}>
          <thead>
            <tr className="user-management-table-heading">
              <th>Webinar Title</th>
              <th>Size</th>
              <th>Available Seats</th>
              <th>Cost</th>
              <th>Status</th>
              <th>Active</th>
              <th>Detail</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {displayedWebinars.length > 0 ? (
              displayedWebinars.map((webinar) => (
                <tr key={webinar.id} className="user-management-table-detail">
                  <td style={{ cursor: "default" }}>{webinar.webinar_title}</td>
                  <td style={{ cursor: "default" }}>{webinar.ticket_size}</td>
                  <td style={{ cursor: "default" }}>{GetAvailableSeats(webinar.id)}</td>
                  <td style={{ cursor: "default" }}>{webinar.ticket_price}</td>
                  <td style={{ cursor: "default" }}>{GetWinnerNumber(webinar.id)?.winner_number ? "Completed" : "On Going"}</td>
                  <td style={{ cursor: "pointer" }}>
                    <Form.Check
                      type="switch"
                      id={`custom-switch-${webinar.id}`}
                      defaultChecked={webinar.status}
                      onChange={(e) => handleSwitchChange(webinar.id, e.target.checked)}
                    />
                  </td>
                  <td>
                    <span className="detail-icon "  onClick={() => navigate("/edit-webinar/" + webinar.id)}>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.84625 5.4975C9.07125 5.9475 9.1875 6.43125 9.1875 6.9375C9.1875 8.69625 7.75875 10.125 6 10.125C4.24125 10.125 2.8125 8.69625 2.8125 6.9375C2.8125 5.17875 4.24125 3.75 6 3.75C6.02625 3.75 6.09 3.75 6.1575 3.7725C6.285 3.81375 6.37875 3.9225 6.405 4.05375C6.5625 4.8075 7.23375 5.35875 8.00625 5.35875C8.14875 5.35875 8.2875 5.34 8.4075 5.30625C8.58375 5.2575 8.76375 5.34 8.84625 5.4975ZM6 1.875C3.435 1.875 0.806247 4.125 0.378747 6.68625C0.344997 6.88875 0.483747 7.08375 0.686247 7.1175C0.888747 7.15125 1.08375 7.0125 1.1175 6.81C1.45125 4.83375 3.6075 2.625 6 2.625C8.3925 2.625 10.5487 4.83375 10.8787 6.81C10.9087 6.99375 11.07 7.125 11.25 7.125C11.2687 7.125 11.2912 7.125 11.3137 7.12125C11.5162 7.0875 11.655 6.8925 11.6212 6.69C11.1937 4.125 8.565 1.875 6 1.875Z"
                          fill="#313D15"
                        />
                      </svg>
                    </span>
                  </td>
                  <td>
                    {GetAvailableSeats(webinar.id) > 0 ? (
                      <span className="webinar-result-ongoing">On Going</span>
                    ) : (
                      !GetWinnerNumber(webinar.id)?.winner_number && (
                        <span className="webinar-result" onClick={() => openModal(webinar.webinar_title, webinar.id)}>Add Winner</span>
                      )
                    )}
                    {GetWinnerNumber(webinar.id)?.winner_number && (
                      <span className="winner-number" onClick={() => openModal(webinar.webinar_title, webinar.id)}>
                        {GetWinnerNumber(webinar.id)?.winner_number} winner
                      </span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">Data not found</td>
              </tr>
            )}
          </tbody>
        </Table>
        {filteredWebinarList.length > 0 && (
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        )}
      </div>
      {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
    </div>
  );
};

export default WebinarManagement;
