

const CheckValid =(value, states)=>{
    if(states?.type === "fullname"){
        if(value !==""){
            return states?.error("");
        }else{
            return states?.error("full name can not be empty");
        }
    }
    if(states?.type === "email"){
        if(value !==""){
            return states?.error("");
        }else{
            return states?.error("email can not be empty");
        }
    }
    if(states?.type === "password"){
        if(value !==""){
            return states?.error("");
        }else{
            return states?.error("password can not be empty");
        }
    }
    if(states?.type === "newpassword"){
        if(value !==""){
            return states?.error("");
        }else{
            return states?.error("new password can not be empty");
        }
    }
    if(states?.type === "confirmpassword"){
        if(value !==""){
            return states?.error("");
        }else{
            return states?.error("confirm password can not be empty");
        }
    }
    if(states?.type === "mobile"){
        if(value !==""){
            return states?.error("");
        }else{
            return states?.error("mobile number can not be empty");
        }
    }
    if(states?.type === "webinar_title"){
        if(value !==""){
            return states?.error("");
        }else{
            return states?.error("webinar title  can not be empty");
        }
    }
    if(states?.type === "product_price"){
        if(value !==""){
            return states?.error("");
        }else{
            return states?.error("product cost  can not be empty");
        }
    }
    if(states?.type === "webinarimage"){
        if(value !=="" || value!==null){
            return states?.error("");
        }else{
            return states?.error("webinar image can not be empty");
        }
    }
 
    if(states?.type === "ticket_price"){
        if(value !==""){
            return states?.error("");
        }else{
            return states?.error("ticket price  can not be empty");
        }
    }
    if(states?.type === "ticket_size"){
        if(value !==""){
            return states?.error("");
        }else{
            return states?.error("ticket size  can not be empty");
        }
    }
    if(states?.type === "description"){
        if(value !==""){
            return states?.error("");
        }else{
            return states?.error("description number can not be empty");
        }
    }
}

export default CheckValid