 const getLetter =(code) =>{
    switch (code) {
        case "01":
            return "A";
        case "02":
            return "B";
        case "03":
            return "C";
        case "04":
            return "D";
        case "05":
            return "E";
        case "06":
            return "F";
        case "07":
            return "G";
        case "08":
            return "H";
        case "09":
            return "I";
        case "10":
            return "J";
        case "11":
            return "K";
        case "12":
            return "L";
        case "13":
            return "M";
        case "14":
            return "N";
        case "15":
            return "O";
        case "16":
            return "P";
        case "17":
            return "Q";
        case "18":
            return "R";
        case "19":
            return "S";
        case "20":
            return "T";
        case "21":
            return "U";
        case "22":
            return "V";
        case "23":
            return "W";
        case "24":
            return "X";
        case "25":
            return "Y";
        default:
            return "Invalid code";
    }
}
export default  getLetter

