import React, { useEffect, useState } from "react";
import { InputGroup, Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { getOnsearch } from "../../redux/actions/adminAction";

const Header = () => {
    const LocationURL = useLocation().pathname;
    const id = parseInt(useParams()?.id);
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { userList,webinarList } = useSelector(state => state.adminReducers);
    const [userData, setUserData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const webinarDetail = webinarList?.list?.filter((webinar) => webinar?.id === id)[0]

    const navigate = useNavigate()
    function handleClick() {
        navigate('/add-webinar')
    }
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getOnsearch(""))
        setSearchQuery("")
    }, [LocationURL])

    useEffect(() => {
        if (id &&userList) {
            const user = userList.list?.filter((user) => user.id === id);
            setUserData(user[0]);
        }
    }, [id, userList]);

    return (<section className="header">
        <div className="header">
            {(LocationURL === "/user-management" || LocationURL === "/webinar-management") && <div className="row g-0" style={{ marginTop: "16px" }}>
                <div className="col-6">
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-default">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1_282)">
                                    <path d="M7 12C9.76142 12 12 9.76142 12 7C12 4.23858 9.76142 2 7 2C4.23858 2 2 4.23858 2 7C2 9.76142 4.23858 12 7 12Z" stroke="#313D15" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10.5359 10.5344L14.0003 13.9987" stroke="#313D15" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_282">
                                        <rect width="16" height="16" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            style={{ height: "33px", borderLeft: "none",borderRight:"none" }}
                            onChange={(e) => {
                                dispatch(getOnsearch(e.target.value.trim()))
                                setSearchQuery(e.target.value)
                            }}
                            onKeyDown={(e) => dispatch(getOnsearch(e.target.value.trim()))}
                            value={searchQuery}
                        />
                        <InputGroup.Text>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1_324)">
                                <path d="M6.90625 6.90625C7.78645 6.90625 8.5 6.1927 8.5 5.3125C8.5 4.4323 7.78645 3.71875 6.90625 3.71875C6.02605 3.71875 5.3125 4.4323 5.3125 5.3125C5.3125 6.1927 6.02605 6.90625 6.90625 6.90625Z" stroke="#313D15" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.1562 13.2812C12.0365 13.2812 12.75 12.5677 12.75 11.6875C12.75 10.8073 12.0365 10.0938 11.1562 10.0938C10.276 10.0938 9.5625 10.8073 9.5625 11.6875C9.5625 12.5677 10.276 13.2812 11.1562 13.2812Z" stroke="#313D15" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.5 5.3125H14.3438" stroke="#313D15" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.65625 5.3125H5.3125" stroke="#313D15" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.75 11.6875H14.3438" stroke="#313D15" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.65625 11.6875H9.5625" stroke="#313D15" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_324">
                                    <rect width="17" height="17" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        </InputGroup.Text>
                    </InputGroup>
                </div>
                {LocationURL === "/webinar-management" && <div className="col-6">
                    <div className="float-end pe-4">
                        <Button type="submit" className="button float-end rounded" style={{ border: "unset" }} onClick={handleClick}>
                            + Add Webinar
                        </Button>
                    </div>
                </div>}
                {LocationURL === "/user-management" && <p>User Management</p>}
                {LocationURL === "/webinar-management" && <p>Webinar Management</p>}
            </div>}
            {LocationURL === "/user-profile" && <p className="mt-3">Profile Information</p>}
            {LocationRoute === "edit-user" && <div className="mt-3 mb-3"> <a className="bread-crumb mt-3" href="/user-management">User Management {">"} </a><a style={{ color: "#95BC3A" }}>{userData?.full_name}</a></div>}
            {(
                LocationURL === "/add-webinar" || LocationRoute === "edit-webinar"
            ) && (
                    <div className="mt-3 mb-3"> <a className="bread-crumb mt-3" href="/webinar-management">Webinars Management {">"}  </a><a style={{ color: "black",fontWeight:"500" }}>{LocationRoute === "add-webinar" ? "Add New Webinar" : webinarDetail?.webinar_title}</a></div>
                )}            {/* {(LocationURL !== "/user-management" && LocationURL !== "/webinar-management" && LocationURL !== "/user-profile" && LocationURL ==="webinar-detail") && <h6 className="mt-4 mb-3">User Management {">"} pravin</h6>}
            {(LocationURL !== "/user-management" && LocationURL !== "/webinar-management" && LocationURL !== "/user-profile" && LocationURL !=="webinar-detail") && <h6 className="mt-4 mb-3">User Management {">"} pravin</h6>} */}
        </div>
    </section>)
}

export default Header;